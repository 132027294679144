<template>
    <div class="aboutContainer">
        <div class="container">

            <h1>
                BIG BOX 隱私權
            </h1>

            <p>
                個人資料保護政策
                BIG BOX(盒子彈科技股份有限公司)為提供給您更多完善及多元之服務及商品，
                對於您所提供與BIG BOX之個人資料，將依個人資料保護法之規定來使用、
                保管您之個人資料，並維護您的隱私權。
                同時，在此對於您個人資料之使用、蒐集及管理，僅限於本公司內部之使用，並絕不對外公開。
            </p>

            <h2>
                個人資料保護聲明
            </h2>

            <p>於使用本平台或其他有關服務起至當事人要求停止使用或BIG
                BOX停止提供服務之日為止之期間，使用者同意我們若將其公司或服務轉讓與第三人時，得將前揭個人資料與技術資料暨使用者之同意內容一併轉讓與該第三人。您之個人資料將用於BIG
                BOX營運之地區，除了供內部做為網站流量和整體網路使用行為各項分析外，並運用於BIG BOX進行各項行銷推廣活動與評估及提昇本程式之服務品質，
                對於您的個人資料蒐集依據「法務部頒佈之個人資料保護法特定目的修正」本公司將為以下利用：</p>

            <ul>
                <li>人事管理(法定特定項目002)</li>
                <li>代理與仲介業務(法定特定項目020)</li>
                <li>存款與匯款(法定特定項目036)</li>
                <li>行銷(包含金控共同行銷業務) (法定特定項目040)</li>
                <li>非公務機關依法定義務所進行個人資料之蒐集處理及利用(法定特定項目063)</li>
                <li>契約、類似契約或其他法律關係事務(法定特定項目069)</li>
                <li>消費者、客戶管理與服務(法定特定項目090)</li>
                <li>商業與技術資訊(法定特定項目098)</li>
                <li>採購與供應管理法定特定項目(法定特定項目107)</li>
                <li>教育或訓練行政(法定特定項目109)</li>
                <li>資(通)訊服務(法定特定項目135)</li>
                <li>網路購物及其他電子商務服務(法定特定項目148)</li>
                <li>輔助性與後勤支援管理(法定特定項目150)</li>
                <li>廣告或商業行為管理(法定特定項目152)</li>
                <li>調查、統計與研究分析(法定特定項目157)</li>
            </ul>


            <h2>
                Cookies隱私權政策
            </h2>
            <p>
                我們使用Google流量分析（Google
                Analytics）來分析使用者如何使用本網站。Google流量分析是Google公司所提供的網路分析服務。為了統計回報與分享給其他Google的服務，Google將使用蒐集到的數據來追蹤及分析本網站之使用者。Google可能會使用該些數據以客製化及個人化其網絡上之廣告。Google流量分析之特徵在於實現本網站之Display
                Advertising（人口統計及網路回報）。Google使用及分享該些Google流量分析依您造訪本網站而蒐得之資訊，同樣受Google流量分析之使用者條款及隱私權政策限制。
                我們在BIG
                BOX服務中使用特定之cookies（如Google流量分析cookies）及其他類似科技。使用這些cookies目的在得使您使用特定服務項目（例如使您完成註冊或獲取資訊），亦使我們得瞭解並儲存您未來拜訪網站之偏好設定、分依使用者模式、追蹤廣告，並獲取與編輯網站互動與網站流量之數據，以利於我們在未來能提供更佳的網站使用經驗及工具。我們可能會與第三方服務提供者合作，以協助我們更瞭解造訪網站之來客。除了幫助我們經營並改進營運事業之目的外，第三方服務提供者不得使用我們蒐集而來之資訊。
                您可以（透過瀏覽器或在裝置上設定）拒絕使用cookies，但您須留意這樣可能導致您無法完整的使用BIG BOX。
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PrivatePolicy"
    }
</script>

<style scoped>

</style>